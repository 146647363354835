body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media screen {
  #print-only {
    display: none;
  }
  #print-hide {
    visibility: hidden;
    position: fixed;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  #print-only {
    display: block;
  }

  #print-hide {
    visibility: visible;
    position: fixed;
  }

  #scrollable-print {
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    overflow: visible;
    visibility: visible;
  }
  #print-chart {
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    height: auto !important;
    width: auto !important;
  }
  #print-none {
    display: none;
  }
}
